import get from 'lodash/get'

const facebookPixel = userConfig => {
  // return object for analytics to use
  return {
    /* All plugins require a name */
    name: 'facebook-pixel',
    /* Everything else below this is optional depending on your plugin requirements */
    config: {
      trackingId: userConfig.trackingId,
    },
    initialize: ({ config }) => {
      if (!config.trackingId)
        throw new Error('No Facebook Pixel trackingId defined')

      !(function(f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      )

      window.fbq('init', config.trackingId)

      return window.fbq
    },
    page: () => {
      if (!window.fbq) return
      return window.fbq('track', 'PageView')
    },
    track: ({ payload }) => {
      if (!window.fbq) return

      if (
        window.location.href.indexOf('-staging.xyz') >= 0 ||
        window.location.href.indexOf('.lvh.me:') >= 0
      )
        return

      const { event, meta, anonymousId, properties, options } = payload
      const mode = get(options, 'fbq.mode', 'trackCustom')
      const _event = get(options, 'fbq.event', event)
      const tokenConversion = document.querySelector(
        'meta[name="facebook-conversion-token"]'
      ).content
      const pixelId = document.querySelector('meta[name="facebook-pixel-id"]')
        .content

      try {
        fetch(
          `https://graph.facebook.com/v13.0/${pixelId}/events?access_token=${tokenConversion}`,
          {
            method: 'POST',
            body: JSON.stringify({
              data: [
                {
                  event_id: anonymousId,
                  event_name: _event,
                  event_time: new Date(meta.timestamp).toISOString(),
                  action_source: 'website',
                  event_source_url: window.location.href,
                  user_data: {
                    ph: [null],
                    client_user_agent: window.navigator.userAgent,
                    client_ip_address: null,
                  },
                  custom_data: properties,
                },
              ],
            }),
          }
        )
      } catch (error) {
        console.log(error)
      }

      return window.fbq(mode, _event, properties)
    },
    identify: ({ payload }) => {
      if (!window.fbq) return

      const { userId, anonymousId, traits } = payload
      return window.fbq('trackCustom', 'identify', {
        anonymousId,
        userId,
        traits,
      })
    },
    loaded: () => !!window.fbq,
  }
}

export default facebookPixel
