import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import googleTagManager from '@analytics/google-tag-manager'
import facebookPixel from './plugins/facebookPixel'

document.addEventListener('DOMContentLoaded', () => {
  const googleAnalyticsId = document.querySelector(
    'meta[name="google-analytics-id"]'
  )?.content
  const facebookPixelId = document.querySelector(
    'meta[name="facebook-pixel-id"]'
  )?.content
  const tagManagerId = document.querySelector('meta[name="tag-manager-key"]')
    ?.content

  let plugins = []

  if (googleAnalyticsId) {
    plugins.push(
      googleAnalytics({
        measurementIds: [googleAnalyticsId],
      })
    )
  }

  if (tagManagerId) {
    plugins.push(googleTagManager({ containerId: googleTagManager }))
  }

  if (facebookPixelId) {
    plugins.push(facebookPixel({ trackingId: facebookPixelId }))
  }

  const analytics = Analytics({
    app: 'Clearworks',
    plugins,
  })

  analytics.page()

  window.analytics = analytics
})
